import alert from './alert.svg';
import alertEmpty from './alert-empty.svg';
import spinner from './spinner.svg';
import checkCircle from './check-circle.svg';
import eye from './eye.svg';
import eyeOff from './eye-off.svg';
import telephone from './telephone.svg';
import telephoneOutline from './telephoneOutline.svg';
import google from './google.svg';
import apple from './apple.svg';
import clock from './clock.svg';
import bigAlert from './bigAlert.svg';
import bigCheckCircle from './bigCheckCircle.svg';
import email from './email.svg';
import sadFace from './sadFace.svg';
import warning from './warning.svg';

export const SpinnerSvg = spinner;
export const AlertSvg = alert;
export const AlertEmptySvg = alertEmpty;
export const CheckCircleSvg = checkCircle;
export const EyeSvg = eye;
export const EyeOffSvg = eyeOff;
export const TelephoneSvg = telephone;
export const TelephoneOutlineSvg = telephoneOutline;
export const AppleSvg = apple;
export const GoogleSvg = google;
export const ClockSvg = clock;
export const BigAlertSvg = bigAlert;
export const BigCheckCircleSvg = bigCheckCircle;
export const EmailSvg = email;
export const SadFaceSvg = sadFace;
export const WarningSvg = warning;
