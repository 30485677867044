import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner';
import cx from 'classnames';

interface ButtonProps extends React.ComponentPropsWithRef<'button'> {
  className?: string;
  isLoading?: boolean;
  color?: ButtonColor;
}

type ButtonColor = 'blue' | 'lightBlue' | 'green' | 'lightGreen';

const ButtonTheme: { [key in ButtonColor]: string } = {
  blue: 'text-white bg-inception-primary-blue-100 hover:bg-inception-primary-blue-80',
  lightBlue: 'text-inception-primary-blue-100 bg-inception-primary-blue-60 hover:bg-inception-primary-blue-40',
  green: 'text-white bg-inception-secondary-green-100 hover:bg-inception-secondary-green-80',
  lightGreen: 'text-inception-secondary-green-100 bg-inception-tertiary-green-40 hover:bg-inception-tertiary-green-20',
};

const disabledTheme = 'text-white bg-inception-white-80 cursor-not-allowed';

const Button: React.FC<ButtonProps> = ({ disabled, className, children, color = 'lightGreen', isLoading, ...rest }) => {
  const themeClass = disabled ? disabledTheme : ButtonTheme[color];
  return (
    <button
      disabled={disabled}
      {...rest}
      className={cx(
        'w-full h-12 px-4 text-sm font-medium focus:outline-none focus:shadow-input rounded',
        themeClass,
        className,
      )}>
      {isLoading && <LoadingSpinner className='absolute mt-0.25' />}
      {children}
    </button>
  );
};

export default Button;
